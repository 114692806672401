.amiiboDetailsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0 22vh 0;
}

.amiiboDetailsBackground {
    position: absolute;
    height: 300px;
    width: 100%;
    z-index: -1;
    filter: saturate(4);
}

.amiiboDetailsNameHeader {
    margin: 10px 0 0 0;
}

.amiiboDetailsSeriesHeader {
    margin: 0 0 20px 0;
}

.amiiboDetailsDetails {
    margin: 5px;
}

.amiiboDetailsReleaseDates {
    list-style-type: none;
    margin: 5px;
}

.amiiboDetailsImage {
    margin-bottom: 10px;
    max-height: 400px;
}

.amiiboDetailsTextBoxContainer {
    margin-top: 5px;
}

.amiiboDetailsCollectionButton {
    padding: 5px 10px 5px 10px;
    border-radius: 8px;
    border: 1px solid black;
    cursor: pointer;
    margin: 5px;
    background: transparent;
}