.TabbedTextBoxContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TabbedTextBoxTabsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.TabbedTextBoxTab {
    padding: 3px;
    border: 1px solid rgb(179, 179, 179);
}

.TabbedTextBoxTab.selected {
    border: 1px solid black;
}