.footerContainer {
    position: absolute;
    bottom: 0;
    margin-top: 20px;
    width: 100%;
    min-height: 20vh;
    background: #494949;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footerDisclaimer {
    margin: 20px;
}

.footerTOS {
    margin: 0px 20px 20px 20px;
    color: white;
}

