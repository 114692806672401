.amiiboCardContainer {
    position: relative;
    height: 30vh;
    width: 24vh;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-shadow: 0px 0px 3px black;
    margin: 20px auto;
}
.amiiboCardContentContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.amiiboCardImage {
    position: relative;
    height: 100%;
}

.amiiboCardTextContainer {
    background: white;
    width: 100%;
    position: absolute;
    bottom: 0;
    color: black;
    border-radius: 0 0 10px 10px;
    padding: 2px 0;
}

.amiiboCardNameText {
    font-family: "Helvetica";
    font-size: 3vh;
    margin: 0;
}

.amiiboCardSeriesText {
    font-family: "Helvetica";
    font-size: 2vh;
    margin: 0;
}

.amiiboCardSelectedIcon {
    position: absolute;
    top: 0;
    right: 0;
    height: 1vh;
    margin: 10px;
    z-index: 1;
}

.amiiboCardAddIcon {
    height: 5vh;
    width: 5vh;
    mask-image: url("../../Assets/add.svg");
    background-color: #46FF00;
    border: 1px solid black;
}

.amiiboCardDeleteIcon {
    height: 5vh;
    width: 5vh;
    mask-image: url("../../Assets/delete.svg");
    background-color: #FF0000;
    border: 1px solid black;
}