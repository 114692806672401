.textBoxContainer {
    width: 80vw;
    border: 1px solid black;
    padding: 25px;
}

.textBoxHeader {
    margin: 10px 0;
}

.textBoxBody {
    margin: 0;
}