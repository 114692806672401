
.homePageContainer {
    padding: 50px 0 22vh 0;
    position: relative;
}
.homePageFirstCarousel {
    overflow: hidden;
}

.homePageFirstCarouselImage {
    max-height: 400px;
}

.homePageCollectionHeader {
    font-size: 40px;
}

.homePageCollectionCarousel {
    margin-left: 40px;
}

.react-multiple-carousel__arrow {
    z-index: 2;
}