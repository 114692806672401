.largeCaourselItemContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100%;
    filter: saturate(4);
}

.largeCarouselItemHeader {
    margin: 0 0 0 20px;
    font-size: 4vw;
    align-self: flex-start;
}

.largeCarouselItemCharacterName {
    position: absolute;
    font-size: 20vw;
    z-index: 0;
}

.largeCarouselItemImage {
    height: 400px;
    width: auto;
    z-index: 1;
    filter: saturate(0.25);
}

@media (max-width: 600px) {
    .largeCarouselItemCharacterName {
        top: 65%;
        z-index: 2;
        text-shadow: 0 0 5px black;
    }
}