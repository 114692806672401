.mobileNavBarContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: white;
    position: fixed;
    left: 0;
    top: 0;
    border-radius: 0px 20px 20px 0px;
    z-index: 10;
    overflow-x: hidden;
    transition: 0.5s;
    box-shadow: 1px 1px 3px;
    white-space: nowrap;
}

.mobileNavBarCloseZone {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9;
}

.mobileNavBarBar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: black;
}

.mobileNavBarHamburger {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    margin: 10px;
}

.mobileNavBarWidth {
    width: 300px;
}

.noWidth {
    width: 0;
}

.mobileNavBarContentContainer {
    margin-top: 30px;
}

.mobileNavBarH2 {
    font-size: Large;
}

.mobileNavBarLinksContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobileNavBarLink {
    color: black;
    margin: 5px 0px;
}

.mobileNavBarSearch {
    padding: 5px 10px 5px 25px;
    border-radius: 8px;
    background: url("../../Assets/search_icon.svg") no-repeat 2% center;
    background-size: 18px;
    border: none;
    background-color: #EAEAEA;
}

.mobileNavBarAddRemoveButton {
    padding: 5px 10px;
    border-radius: 8px;
    border: 1px solid black;
    margin: 10px 0 5px 0;
    font-size: medium;
    background: transparent;
    cursor: pointer;
}
.mobileNavBarConfirmChangesButton {
    padding: 5px 10px;
    border-radius: 8px;
    border: 1px solid black;
    margin: 10px 0 5px 0;
    font-size: medium;
    background: transparent;
    cursor: pointer;
}

.mobileNavBarAddRemoveButton.active {
    background: #28B1FF;
    color: white;
}
@media (max-width: 1450px) {
    .mobileNavBarHamburger {
        display: block;
        cursor: pointer;
    }

    .mobileNavBarHamburger.active .mobileNavBarBar:nth-child(2) {
        opacity: 0;
    }

    .mobileNavBarHamburger.active .mobileNavBarBar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .mobileNavBarHamburger.active .mobileNavBarBar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }
}