.loadingSpinnerContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    border: 16px solid #f3f3f3;
    border-top: 16px solid #363636; 
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}