.amiiboLibraryContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0 20vh 0;
    margin: 0px 20px;
}

.amiiboLibraryCardsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.amiiboLibraryDesktopControls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.amiiboLibrarySearch {
    margin: 5px;
    padding: 5px 10px 5px 25px;
    border-radius: 8px;
    background: url("../../Assets/search_icon.svg") no-repeat 2% center;
    background-size: 18px;
    border: none;
    background-color: #EAEAEA;
}

.amiiboLibraryAddRemoveButton {
    padding: 5px 10px 5px 10px;
    border-radius: 8px;
    border: 1px solid black;
    background: white;
    cursor: pointer;
    margin: 5px;
}

.amiiboLibraryAddRemoveButton:hover {
    background: #D8F1FF;
}

.amiiboLibraryAddRemoveButton.active {
    background: #28B1FF;
    color: white;
}

.amiiboLibraryConfirmChangesButton {
    padding: 5px 10px 5px 10px;
    border-radius: 8px;
    border: 1px solid black;
    background: white;
    cursor: pointer;
    margin: 5px;
}
.amiiboLibraryConfirmChangesButton:hover {
    background: #D8F1FF;
}

.amiiboLibraryOwnershipCheckboxes {
    margin: 5px;
}